import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentLang = localStorage.getItem('lang');

  private _translate = inject(TranslateService);

  initLanguage(){
    this._translate.addLangs(["en-US", "es-ES"]);
    let defaultLang = this.currentLang ? this.currentLang : this.getBrowserLang(); 
    this.changeLang(defaultLang)
  }
  
  getBrowserLang(){
    let browserLang = this._translate.getBrowserLang();
    return browserLang == 'es' ? 'es-ES' : 'en-US';
  }

  changeLang(lang: string){
    this._translate.setDefaultLang(lang)
    this._translate.use(lang)
    localStorage.setItem('lang', lang)
  }
}
